import * as React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import PropTypes from "prop-types"

export default function DatePicker({ date, setDate, isFetching, companyAllowsWeekends }) {
  const disableWeekends = date => {
    if(companyAllowsWeekends) return false;

    return date.day() === 0 || date?.day() === 6
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StaticDatePicker
        orientation="portrait"
        value={date} 
        disabled={isFetching}
        onChange={newValue => setDate(newValue)}
        disablePast
        slotProps={{
          toolbar: { hidden: true },
          actionBar: { actions: [] }
        }}
        shouldDisableDate={disableWeekends}
      />
    </LocalizationProvider>
  );
}

DatePicker.propTypes = {
  date: PropTypes.object,
  setDate: PropTypes.func,
  isFetching: PropTypes.bool,
  companyAllowsWeekends: PropTypes.bool,
};