import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import selectors from "selectors";
import { fetchChatHistory } from "actions/chat";
import { Page, Card } from "components/ui";
import { formatDateToLocal, fromTo } from "utils/misc";
import { nth, propOr } from "ramda";
import useTranslate from "hooks/use-translate-language";
import moment from "moment";
import { getBrowserTimezone, convertDateToLocal } from "utils/misc";

const ChatHistory = ({ setInUpcomingAppointments }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const chats = useSelector(selectors.getChatHistory);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    setIsFetching(true);
    dispatch(fetchChatHistory()).finally(() => {
      setIsFetching(false);
    });
  }, [dispatch]);
  useEffect(() => {
    return () => {
      setInUpcomingAppointments(false);
    };
  }, []);

  return (
    <Page.Container>
      <Page.Header title={translate("videoChatHistory")} goBack />
      <Page.Content
        isFetching={isFetching}
        isEmpty={chats.length === 0}
        emptyMessage={translate("noChatHistory")}
      >
        {chats.map((chat, index) => (
          <ChatItem {...chat} key={index} />
        ))}
      </Page.Content>
    </Page.Container>
  );
};

ChatHistory.propTypes = { setInUpcomingAppointments: PropTypes.func };

export default ChatHistory;

const ChatItem = ({ chats = [] }) => {
  const speaksSpanish = useSelector(selectors.getSpeaksSpanish);
  const translate = useTranslate();
  const initialChat = nth(0, chats);
  const chatDetails = propOr({}, "chat_details", initialChat);

  const browserTimezone = getBrowserTimezone();
  const currTimezone = `(${moment.tz(browserTimezone).format("z")})`;
  const startTimeFromCSTToBrowser = convertDateToLocal(
    chatDetails.start_time,
    false
  );
  const endTimeFromCSTToBrowser = convertDateToLocal(
    chatDetails.end_time,
    false
  );

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        margin: 5,
        display: "flex",
        flex: 1,
        justifyContent: "center"
      }}
    >
      <Card
        style={{
          position: "absolute",
          left: 10,
          width: 110,
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 8,
          backgroundColor: "#24ABFB"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "white",
            fontSize: 14,
            fontFamily: "Poppins-Medium"
          }}
        >
          <div>
            {formatDateToLocal(chatDetails.date_time, speaksSpanish, "MMM")}
          </div>
          <div>
            {formatDateToLocal(chatDetails.date_time, speaksSpanish, "DD")}
          </div>
          <div>
            {formatDateToLocal(chatDetails.date_time, speaksSpanish, "YYYY")}
          </div>
        </div>
      </Card>
      <Card
        style={{
          width: "100%",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          padding: 5,
          paddingLeft: 100,
          paddingBottom: 10,
          paddingTop: 20,
          marginLeft: 30
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ fontWeight: "bold" }}>
            {translate(chatDetails?.title)}
          </div>
        </div>
        <div>
          {fromTo(
            startTimeFromCSTToBrowser,
            endTimeFromCSTToBrowser,
            translate("to")
          )}
          {" " + currTimezone}
        </div>
        <div style={{ display: "flex" }}>
          {`${
            chatDetails.chat_type === 1
              ? translate("textChatWith")
              : translate("videoChatWith")
          } ${chatDetails?.dme_details?.name ?? ""}`}
        </div>
        <div style={{ color: "#141C36" }}>{chatDetails?.product_name}</div>
      </Card>
    </div>
  );
};

ChatItem.propTypes = { chats: PropTypes.arrayOf(PropTypes.object) };
