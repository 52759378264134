import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { fetchChatTopics, fetchCoachAvailability } from "actions/chat";
import moment from "moment";
import AvailableTimeSlots from "./available-time-slots";
import useTranslate from "hooks/use-translate-language";
import selectors from "selectors";
import { useSelector, useDispatch } from "react-redux";
import { convertTimeFromCSTToBrowserTz } from "utils/misc";

import { FormControl, MenuItem, Select, InputLabel } from "@mui/material";

const TimeSlotSearchForm = ({ handleTimeSlotSelect, formControlStyle }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const speaksSpanish = useSelector(selectors.getSpeaksSpanish);

  const [isFetchingTimeslots, setIsFetchingTimeslots] = useState(false);

  const topic_categories = useSelector(selectors.getChatTopics);
  const [topicCategory, set_TopicCategory] = useState("");

  useEffect(() => {
    dispatch(fetchChatTopics());
  }, [dispatch]);
  const [availableSlotsResults, setAvailableTimeSlotsResult] = useState(null);
  const [date, setSelectedDate] = useState(moment());

  useEffect(() => {
    if (topicCategory == "") return;

    setIsFetchingTimeslots(true);

    dispatch(
      fetchCoachAvailability({ date, appointment_topic_id: topicCategory })
    )
      .then(result => {
        if (result.success) {
          result = {
            ...result,
            slots: result.slots.map(slot => ({
              ...slot,
              AvailabilitySlotsTimes: slot.AvailabilitySlotsTimes.map(
                availability => ({
                  ...availability,
                  Start: convertTimeFromCSTToBrowserTz(availability.Start),
                  End: convertTimeFromCSTToBrowserTz(availability.End)
                })
              )
            }))
          };
          setAvailableTimeSlotsResult(result);
        }
      })
      .finally(() => {
        setIsFetchingTimeslots(false);
      });
  }, [date, dispatch, topicCategory]);

  return (
    <div>
      <FormControl
        style={{
          width: formControlStyle?.width || "25%",
          marginBottom: "20px",
          marginTop: "20px"
        }}
      >
        <InputLabel>{translate("chooseATopic")}</InputLabel>
        <Select
          value={topicCategory}
          onChange={({ target }) => set_TopicCategory(target.value)}
        >
          {topic_categories.map(
            ({ topic_id, topic_name, spanish_topic_name }) => (
              <MenuItem key={topic_id} value={topic_id}>
                {!speaksSpanish ? topic_name : spanish_topic_name ?? topic_name}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      <div
        style={{
          marginTop: 20,
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {topicCategory != "" && (
          <AvailableTimeSlots
            availableSlotsResults={availableSlotsResults}
            handleTimeSlotSelect={handleTimeSlotSelect}
            topicCategory={topicCategory}
            date={date}
            setSelectedDate={setSelectedDate}
            isFetchingTimeslots={isFetchingTimeslots}
          />
        )}
      </div>
    </div>
  );
};

TimeSlotSearchForm.propTypes = {
  handleTimeSlotSelect: PropTypes.func.isRequired,
  chat_type: PropTypes.number,
  formControlStyle: PropTypes.object
};

TimeSlotSearchForm.defaultProps = {
  chat_type: 1,
  formControlStyle: {}
};

export default TimeSlotSearchForm;
