import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { AuthorizePatient } from "components/new-email-order/authorize-patient";
import { Page, Spinner } from "components/ui";
import { Card, Button } from "@mui/material";
import { respondToChatNotification, fetchChatDetailsWithoutLoging } from "actions/chat";
import { useDispatch } from "react-redux";
import browserHistory from "browser-history";
import moment from "moment";
import { getBrowserTimezone, convertDateToLocal } from "utils/misc";

const PendingAppointment = () => {
    const location = useLocation();
    const token = queryString.parse(location.search).token;
    const chatId = queryString.parse(location.search).chat;
    const [successMessage, setSuccessMessage] = useState("");
    const [isSubmiting, setIsSubmiting] = useState("");

    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${token}` };

    const dispatch = useDispatch();
    const browserTz = `(${moment.tz(getBrowserTimezone()).format("z")})`


    const handleResponse = async (action) => {
        setIsSubmiting(true);
        const { chat } = await dispatch(fetchChatDetailsWithoutLoging(chatId, headers));
        const result = await dispatch(respondToChatNotification(chatId, action, headers));
        setIsSubmiting(false);

        if (result && result.success) {
            const formattedAction = action == "accept" ? "accepted" : "rejected";
            const startDate = moment(chat.start_time).format('L');
            const startTime = convertDateToLocal(chat.start_time).format('LT')
            const endTime = convertDateToLocal(chat.end_time).format('LT')  

            const msg = `You ${formattedAction} a chat with ${chat.dme_details.name} on ${startDate} from ${startTime} to ${endTime} ${browserTz}`;
            setSuccessMessage(msg);
        }
    }

    return (
        <Page.Container style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
        }}>
            <Page.Content>
                {!successMessage ?
                    <Card style={{ padding: '30px' }}>
                        <h3>You have a new appointment pending confirmation.</h3>
                        <span>Do you want to accept the appointment?</span>
                        <br />
                        {!isSubmiting &&
                            <>
                                <Button
                                    variant="contained"
                                    style={{ marginTop: '15px' }}
                                    color="error"
                                    onClick={() => { handleResponse("decline"); }}>
                                    No
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{ marginLeft: '35px', marginTop: '15px' }}
                                    color="success"
                                    onClick={() => { handleResponse("accept"); }}>
                                    Yes
                                </Button>
                            </>
                        }
                        {isSubmiting && <Spinner style={{ marginTop: '10px' }} />}
                    </Card>
                    :
                    <Card style={{ padding: '30px' }}>
                        <h3>{successMessage}</h3>
                        <br/>
                        <Button variant="contained" onClick={() => browserHistory.push({ pathname: "/login"})}>Click Here To View Upcoming Chats</Button>
                    </Card>
                }
            </Page.Content>
        </Page.Container>)
}

export default () => (
    <AuthorizePatient>
        <PendingAppointment />
    </AuthorizePatient>
);